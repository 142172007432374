/** @format */

import { useRouter } from 'next/router';
import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { breakpointUpTo } from '../../theme/mixins';
import { StatisticsData } from '../../types/page';
import { Container, ContainerWrapper } from './Layout';
import { DataNumbers, DataUnitMeasure, Subtitle } from './Typography';

const Wrapper = styled(Container)<{ $length: number }>`
  font-family: ${({ theme }) => theme.font.title};
  padding-block: clamp(3.75rem, 2.3333rem + 6.2963vw, 8rem);
  gap: ${({ $length }) =>
    $length === 4 ? 'clamp(3.75rem, 2.6867rem + 4.7259vw, 6.94rem)' : 'clamp(3.75rem, 0.6667rem + 13.7037vw, 13rem)'};
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  ${breakpointUpTo.sm`
     flex-direction:column;
     align-items:center;
    `}
`;

const Data = styled.div<{ $length: number }>`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ $length }) => ($length === 4 ? '17rem' : '13rem')};
`;

const DataNumber = styled(DataNumbers)`
  display: flex;
  align-items: baseline;
`;

interface Props {
  data: StatisticsData['fields']['data'];
}

export const SectionInfoSatistics = ({ data }: Props) => {
  const theme = useContext(ThemeContext);
  const { locale } = useRouter();

  return (
    <ContainerWrapper as="section" $color={theme!.color.teal.light}>
      <Wrapper $length={data.length}>
        {data.map(statistics => (
          <Data $length={data.length} key={statistics.label}>
            <DataNumber $color={theme!.color.white}>
              {statistics.prefix && <DataUnitMeasure $color={theme!.color.white}>{statistics.prefix}</DataUnitMeasure>}
              {statistics.statisticsNumber.toLocaleString(locale)}
              {statistics.value && <DataUnitMeasure $color={theme!.color.white}>{statistics.value}</DataUnitMeasure>}
            </DataNumber>

            <Subtitle as="p" $color={theme!.color.yellow}>
              {statistics.label}
            </Subtitle>
          </Data>
        ))}
      </Wrapper>
    </ContainerWrapper>
  );
};
